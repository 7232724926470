<template>
    <div class="center-page">
        <div class="top_change" @click="goBack">
            <div class="l_where">系统设置<span>/</span>{{ centerDate.name }}</div>
            <div class="r_back" @click="goBack">
                <i class="iconfont icon-fanhui"></i>
            </div>
        </div>
        <div class="center-nav">
            <div class="center-cont">
                <div class="jianjie">
                    <div class="pur-nam" style="margin-left:20px">{{ centerDate.name }}</div>
                    <div class="int-nam" style="margin-left:20px">{{ centerDate.describe }}</div>
                    <div class="shop-items" style="margin-left:20px">
                        <div
                        v-for="(item, index) in centerDate.price_group"
                        :key="index"
                        class="shop-but"
                        @click="goPay(item)"
                        >
                        <div class="left-shopping"></div>
                        <div class="shop-text"><span class="daole">￥</span>{{item.price}}<span v-if="centerDate.permissions_id == '1000'">/{{item.number}}条</span></div>
                        <div class="original-text" v-if="item.original_price != 0"> 原价:<span >￥{{item.original_price}}</span></div>
                        </div>            
                    </div>
                </div>
            </div>
            <Divider />
            <div class="bottom-swip">
                <div v-html="centerDate.details_information"></div>
            </div>
        </div>
        
        <Modal v-model="showPay" :mask-closable="false" footer-hide width="600px" >
            <div class="mod-pay-cont">
            <div class="cha-cha" @click="closeMod"><img src="@/assets/setimg/guanbi.png" /></div>
            <div class="pay-title" ><span > {{ centerDate.name }}</span></div> 
            <div class="pay-num"><span class="line"> ￥{{formDate.price}}</span></div>
            <div class="pay-pay">
                <div class="pay-items"> <img @click="goPayMoney(1)" src="@/assets/setimg/weixin.png" /><div style="margin:0 5px"><Radio @on-change="payWechat" v-model="wechat">微信支付</Radio></div> </div>
                <div class="pay-items"> <img @click="goPayMoney(2)"  src="@/assets/setimg/zhifubao.png" /><div style="margin:0 5px"><Radio disabled  @on-change="payMoth" v-model="zfbchat">支付宝支付</Radio></div> </div>
            </div>
            </div>
        </Modal>
        <Modal v-model="payMa" :mask-closable="false" footer-hide width="600px" >
            <div class="mod-pay-cont">
                <div class="cha-cha" @click="closePay"><img src="@/assets/setimg/guanbi.png" /></div>
                <div class="pay-title1" v-if="zfbchat"> <span>请使用支付宝扫码付款</span></div> 
                <div class="pay-title1" v-if="wechat"> <span>请使用微信扫码付款</span></div>           
                <div class="pay-num"><span class="line"> ￥{{formDate.price}}</span></div>
                <div class="pay-ma">             
                    <Spin fix v-if="PayEWM==''">
                        <img class="demo-spin-icon-load" style="width:50px;height:50px"  src="@/assets/setimg/20220720113958.gif" />
                        <div>Loading...</div>
                    </Spin>
                    <img v-if="PayEWM !=''" :src="PayEWM" />
                    <!-- <div v-if="PayEWM==''">请稍等.....</div> -->
                </div>
            </div>
        </Modal>
        <Modal v-model="paySucces" :mask-closable="false" footer-hide width="600px" >
            <div class="mod-pay-cont">
                <div class="cha-cha" @click="closeSuc"><img src="@/assets/setimg/guanbi.png" />
                </div>
            </div>
            <div class="mod-pay-succ">
                <div><img src="@/assets/setimg/zhifuwancheng.png" /></div>
                <span class="titlesuc">恭喜你支付完成!</span>
                <div class="over-list" style="margin-top: 30px">商品订单：<span>{{payFormData.order_no}}</span></div>
                <div class="over-list" >商品名称：<span>{{centerDate.name}}</span></div>
                <div class="over-list" style="margin-bottom:40px">
                    支付金额：
                    <span>¥{{payFormData.pay_money}}</span>
                    <span v-if="payFormData.number  != 0">/{{payFormData.number}}条</span>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { cs_chargepay,get_orderstatus,get_menulist }from "@/api/center.js"; 
import vm from '@/utils/util.js'
export default {
    name: "purchase",
    components: {
        // modal
    },
    data() {
        return {   
            formDate: {
                describe: "",
                id: "",
                number: 0,
                price: "",
                type: "",
            },
            paySucces: false,
            spinShow: false,
            centerDate:{
                icon: "",
                id: "",
                is_menu: "",
                is_rightmenu: "",
                is_submenu: "",
                path: "",
                permissions_name: "",
                permissions_rote: "",
                permissions_id: "",
                route_name: "",
                pid: "",
                type: "",
                plug_id: "",
                hasOriginal: "",
            },
            payFormData: {
                pay_money: "",
                order_no:  "",
                number: 0
            },
            payMa: false,
            showPay: false,
            zfbchat: false,
            wechat: false,
            PayEWM: "",   
            timer: "",   
        };
    },
    created() {        
        this.centerDate = JSON.parse(localStorage.getItem("centerType"))   
        localStorage.setItem("payOver",'')        
    },
    computed: {
        userInfo(){
            return this.$store.state.userInfo
        },
    },
    mounted() {
        var that = this
        vm.$on('demo', function (msg) {
            console.log('msg',msg)
            that.payOK(msg)
        })
    },
    methods: {     
        payOK(a){
            // let a = JSON.parse(e)
            if(a.code == 200){
                if(this.payMa){
                    this.paySucces = true
                    this.payMa = false
                    this.showPay = false
                    this.payFormData.pay_money = a.data.pay_money
                    this.payFormData.order_no = a.data.order_no
                    this.payFormData.number = a.data.number 
                }
                this.get_menulist()
            }else{
                console.log('出错了')
            }
        }, 
        goBack() {
            this.$emit("goBack");
        },
        goPay(e) {
            // this.showPay = true;
            // this.zfbchat = false
            // this.wechat = false            
            this.formDate = e 
            this.payMa = true
            this.goPayMoney(1)            
        },       
        goPayMoney(e){
            if(e == 1){
                this.PayEWM = ""
                this.zfbchat = false
                this.wechat = true
                this.payMa = true
                let data={
                    type:0,
                    price_id: this.formDate.id,
                    uniacid_id: this.userInfo.uniacid_id,
                    application_id: this.centerDate.plug_id,
                    payment_type: 1 ,  //1微信  2支付宝
                }
                cs_chargepay(data).then(res=>{     
                    if(res.code == 400 && this.centerDate.permissions_id != 1000){
                        this.payMa = false
                        return  this.$Message.error(res.msg)
                    }else {
                        this.PayEWM = res.data.qrcode_url
                        this.pollingOrder(res.data.out_trade_no)
                    }
                }).catch(res=>{
                    if(res.code == 400 && this.centerDate.permissions_id != 1000){
                        this.payMa = false
                        return  this.$Message.error(res.msg)
                    }
                })
            }else{
                // this.wechat = false
                // this.zfbchat = true
                // this.payMa = true
            }
        },
        pollingOrder(e){
            this.timer = setInterval(() => {
                this.$store.commit('changePollingSpin', false)
                get_orderstatus({order_no:e}).then(res=>{
                    if(res.data.is_pay == 1){
                        this.payOK(res)
                        clearInterval(this.timer)
                    }
                    this.$store.commit('changePollingSpin', true)
                }).catch(res=>{
                    this.$store.commit('changePollingSpin', true)
                    this.$Message.error(res.msg)
                    clearInterval(this.timer)
                })
            }, 2000)
        },
        closeMod(){
            this.showPay = false
        },
        closePay(){
            this.payMa = false
            clearInterval(this.timer)
        },
        closeSuc(){
            this.paySucces = false
        },
        get_menulist(){
            get_menulist().then(res=>{
                this.$store.commit('setAuth', res.data.unique_auth)  
                this.$store.commit('setMenus', res.data.menuAll)                
                let arr = []
                res.data.menuAll.forEach(item=>{
                    if(item.permissions_rote === 'settingIndex'){
                        arr=item.children
                    }
                })
                this.$store.commit('setSetting', arr)

            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        },
        payMoth(e){
            if(e){
                this. wechat = false
                //  this.zfbchat = true
                //  this.payMa = true
            }
        },
        payWechat(e){
            if(e){
                this.goPayMoney(1)
            }              
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .ivu-modal-close  {
    display: none;
}
::v-deep .bottom-swip img{
    max-width: 100%;
    max-height: 100%;
}
.bottom-swip{
    width: 100%;
    margin-top: 35px;
    overflow: hidden;
}
.cha-cha {
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    color: #fff;
    z-index: 1;
    right: -50px;
    top: -20px;
}
.pay-pay{
    display: flex;
    align-items: center;
    justify-content: center;
    .pay-items{
        padding: 50px 0;
        width: 50%;
        text-align: center;
        img {
            cursor: pointer;
            width: 90px;
            height: 90px;
        }
    }
}
.over-list {
    text-align: start;
    margin: 20px 0px;
    font-size: 15px; 
    color: #000000;
    margin-left: 190px ;
    span {
        color: #666666;
    }
}
.center-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .jianjie {
        width: 100%;
        height: 100%;
    }    
    .pur-nam {
        color: #1b2742;
        font-size: 20px;
        margin-top: 12px ;
        font-family: PingFang SC;
        font-weight: bold;
    }
    .int-nam {
        width: 100%;
        height: 95px;
        margin-top:8px;
        padding: 5px;
        font-family: PingFang SC;
        font-size: 15px;
        color: #545f78;
        // overflow: scroll;
        overflow: hidden;
        // display: block;
    }
    .int-nam::-webkit-scrollbar{
        display: none;
    }
    .shop-items {
        user-select: none;
        display: flex;
        flex-flow: wrap;
        font-family: PingFang SC;
        .shop-but {
            margin-top:10px ;
            padding: 5px 10px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.5px solid #4877e8;
            border-radius: 8px;
            cursor: pointer;
            position: relative;
            .left-shopping {
                margin-left: 10px;
                width: 20px;
                height: 20px;
                padding:  5px;
                background: url('../../../assets/setimg/gouwuche.png') no-repeat 0px 3px;;        
            }
            .shop-text {
                text-align: center;
                padding-left: 10px;
                padding-right: 20px;
                color: #fe511c;
                font-size: 22px;
                span {
                    font-size: 16px;
                    color: #4877e8;
                }
                .daole {
                    font-size: 16px;
                    color: #fe511c;
                }
            }
            .original-text{
                color: #999999;
                font-size: 12px;word-break:keep-all;white-space:nowrap; 
                margin-top:6px ;
                span {
                    text-decoration:line-through
                }
            }
        }
        .shop-but:hover{
            padding: 5px 10px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.5px solid #4877e8;
            border-radius: 8px;
            cursor: pointer;
            position: relative;
            background: #4877E8;
            .shop-text {
                padding-left: 10px;
                padding-right: 20px;
                text-align: center;
                color: #fff;
                font-size: 22px;
                span {
                    font-size: 16px;
                    color: #fff;
                }
                .daole {
                    font-size: 16px;
                    color: #fff;
                }
            }
            .original-text {
                color: #fff;
            }
            .left-shopping {
                margin-left: 10px;
                width: 20px;
                height: 20px;
                padding:  5px;
                background: url('../../../assets/setimg/gouwuchebai.png') no-repeat 0px 3px;        
            }                
        }
    }
}

.mod-pay-succ{
    text-align: center;
    img {
        width: 80px;
        height: 80px;
        margin-top: 50px;
        margin-bottom: 10px;
    }
}
.titlesuc {
    margin-top: 50px;  
    font-size: 25px;
}
.mod-pay-cont{
    text-align: center;
    .pay-title{
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;        
    }    
    .pay-title1 span{
        color: #545F78;
        font-size: 20px;
        font-family: PingFang SC;
    }
    .pay-ma {        
        text-align: center;
        width: 202px;
        height: 202px;
        border: 1px solid #4877E8;
        margin: 50px  175px;
        position: relative;
        img {
            width: 200px;
            height: 200px;
            margin: 0px 0px;
        }
    }
    .pay-num {
        width: 100%;
        height: 40px;
        margin-top:40px ;
        text-align: center;
        color: #4877e8;
    }
    .pay-num .line{
        position: relative;
        display: inline-block;
        padding: 0 30px;
    }    
    .pay-num .line::after{
        content:'';
        width:180px;
        position:absolute;
        top:20px;
        right:-160px;
        border-bottom:1px solid #4877E8;
    }
    .pay-num .line::before{
        content:'';
        width:180px;
        position:absolute;
        top:20px;
        left:-160px;
        border-bottom:1px solid #4877E8;
    }
    span {
        color: #545F78;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
    }
    img {
        width: 40px;
        height: 40px;
    }
}
.center-page {
    .top_change {
        width: 100%;
        cursor: pointer;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        box-sizing: border-box;
        height: 60px;
        .l_where {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1b2742;
            span {
                color: #4877e8;
            }
        }
        .r_back {
            .iconfont {
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877e8;
                }
            }
        }
    }
    .center-nav {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        border-radius: 8px;
        background: white;
        margin-top: 20px;        
    }
}
</style>
<style>
.demo-spin-icon-load{
    animation: ani-demo-spin 3s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
.demo-spin-col{
    top:80px;
    left:80px;
    position: relative;
}
</style>
